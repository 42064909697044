$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).on('resize scroll', function () {
  $('footer').each(function () {
    if ($(this).isInViewport()) {
      $("section.edit-actions.text-end").removeClass('sticky');
      $(".modified").css('padding-bottom', '0');
    } else {
      $("section.edit-actions.text-end").addClass('sticky');
      $(".modified").css('padding-bottom', '78px');
    }
  });
});